<template>
  <div class="platform-admin">
    <div class="platform-admin-aside">
      <AdminSideBar />
    </div>
    <div class="platform-admin-content">
      Admin Platform
    </div>
  </div>
</template>

<script>
import AdminSideBar from "./AdminSideBar.vue";
export default {
  components: { AdminSideBar },
};
</script>

<style lang="scss" scoped>
.platform-admin {
  position: relative;
}
</style>
